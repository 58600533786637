function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/webDiscount/${view}.vue`);
}

function webDisCountPortalloadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/webdiscount/${view}.vue`);
}

const webDiscountRoutes = [
  // 로그인
  {
    path: '/loginView',
    name: 'loginview',
    component: loadView('loginView'),
    meta: {
      title: '웹 할인',
      // onlyGuest: true,
    },
  },
  // 비밀번호 변경
  {
    path: '/changepw',
    name: 'changepw',
    component: loadView('changePw'),
    meta: {
      title: '웹 할인',
    },
  },
  // 웹 할인 - 시청관리자
  {
    path: '/webdiscountlist',
    name: 'webdiscountlist',
    component: loadView('webdiscountList'),
    meta: {
      title: '웹 할인',
      authorization: ['ROLE_CITYHALL', 'ROLE_CITYCOUNCIL', 'ROLE_ADMIN'],
    },
  },
  // 웹 할인 - 시청관리자
  {
    path: '/webdiscounthistory',
    name: 'webdiscounthistory',
    component: loadView('webdiscountHistory'),
    meta: {
      title: '웹 할인',
      authorization: ['ROLE_CITYHALL', 'ROLE_CITYCOUNCIL', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/webdiscount',
    name: 'webdiscount',
    component: loadView('webDiscountView'),
    meta: {
      title: '웹 할인',
      authorization: ['ROLE_CITYHALL', 'ROLE_CITYCOUNCIL', 'ROLE_ADMIN'],
    },
  },

  // 웹 할인 - 포털 사업자
  {
    path: '/webdiscountinfo',
    name: 'webdiscountinfo',
    component: webDisCountPortalloadView('webDiscountInfo'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 안내'],
    },
  },
  {
    path: '/webdiscountuse',
    name: 'webdiscountuse',
    component: webDisCountPortalloadView('webDiscountUse'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 적용'],
      authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/webdiscountuse/detail',
    name: 'webdiscountusedetail',
    component: webDisCountPortalloadView('webDiscountUseDetail'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 적용'],
      authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/webdiscountbuy',
    name: 'webdiscountbuy',
    component: webDisCountPortalloadView('webDiscountBuy'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 구매'],
      authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/webdiscountbuy/history',
    name: 'webdiscountbuyhistory',
    component: webDisCountPortalloadView('webDiscountBuyHistory'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 사용내역'],
      authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
    },
  },
  {
    path: '/webdiscountuse/history',
    name: 'webdiscountusehistory',
    component: webDisCountPortalloadView('webDiscountUseHistory'),
    meta: {
      category: ['웹 할인 서비스', '웹 할인 사용내역'],
      authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
    },
  },
];

export default webDiscountRoutes;
