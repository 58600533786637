function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/reduction/${view}.vue`);
}

const reductionRoutes = [
  /* 일반감면신청 */

  // 비대면 서비스 안내
  // {
  //   path: '/reductioninfo',
  //   name: 'reductioninfo',
  //   component: loadView('ReductionInfo'),
  //   meta: {
  //     category: ['일반감면신청', '비대면 서비스 안내'],
  //   },
  // },

  // 비대면 감면 자격확인
  // 1. 동의
  // {
  //   path: '/reductionagreement',
  //   name: 'reductionagreement',
  //   component: loadView('ReductionAgreement'),
  //   meta: {
  //     category: ['일반감면신청', '일반감면 자격확인'],
  //     authorization: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_CITYHALL', 'ROLE_CITYCOUNCIL'],
  //   },
  // },
  // 2. 입력
  // {
  //   path: '/reductionconfirm',
  //   name: 'reductionconfirm',
  //   component: loadView('ReductionConfirm'),
  //   props: true,
  //   meta: {
  //     category: ['일반감면신청', '일반감면 자격확인'],
  //     authorization: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_CITYHALL', 'ROLE_CITYCOUNCIL'],
  //   },
  // },
  // 3. 결과
  // {
  //   path: '/reductionresult',
  //   name: 'reductionresult',
  //   component: loadView('ReductionResult'),
  //   props: true,
  //   meta: {
  //     category: ['일반감면신청', '일반감면 자격확인'],
  //     authorization: ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_CITYHALL', 'ROLE_CITYCOUNCIL'],
  //   },
  // },

  // 일반감면신청
  // 1. 일반할인 - 안내
  {
    path: '/generaldiscountinfo',
    name: 'generaldiscountinfo',
    component: loadView('GeneralDiscountInfo'),
    meta: {
      category: ['일반감면신청', '일반감면 신청'],
    },
  },
  // 2. 일반할인 - 약관
  {
    path: '/generaldiscount/agreement',
    name: 'generaldiscountagreement',
    component: loadView('GeneralDiscountAgreement'),
    meta: {
      category: ['일반감면신청', '일반감면 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 3. 일반할인 - 정보입력
  {
    path: '/generaldiscount/enrollment',
    name: 'generaldiscountenrollment',
    component: loadView('GeneralDiscountEnrollment'),
    meta: {
      category: ['일반감면신청', '일반감면 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 4. 일반할인 - 신청결과
  {
    path: '/generaldiscount/result',
    name: 'generaldiscountresult',
    component: loadView('GeneralDiscountResult'),
    meta: {
      category: ['일반감면신청', '일반감면 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
];

export default reductionRoutes;
