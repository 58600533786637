import axios from 'axios';

let payWinRef = null;

const methods = {

	goPayPop: (confirmMsg) =>{
		var _vMsg = confirmMsg ? confirmMsg : "결제 기본 메시지";
		this.msgFrom(_vMsg, "pay");
	},

	msgFrom: function(msg, mode) {
		var _vPayFormNm   = document.getElementById("paymentForm");
			var _vBrowserType = "";
		
			if(navigator.appName.indexOf("Microsoft") > -1){
				if(navigator.appVersion.indexOf("MSIE 7") > -1){
					_vBrowserType = "MSIE 7";
				}else if(navigator.appVersion.indexOf(navigator.appVersion.indexOf("MSIE 6") > -1)){
					_vBrowserType = "MSIE 6";
				}
			}
			_vPayFormNm.action = this.getPGUrl("paymentForm", mode);

			var _vEncodingType = "EUC-KR";
			
			if(this.getVersionOfIE() != 'N/A'){
				document.charset = _vEncodingType;		// ie
			}else{
				_vPayFormNm.charset = _vEncodingType;	// else
			}
			
			if(_vPayFormNm.FORWARD.value == 'Y'){
				
				var _vLeft    = (screen.Width - 545) / 2;
				var _vTop     = (screen.Height - 573) / 2;
				var _vWinopts = "left=" + _vLeft + ", top=" + _vTop + ", width=650, height=416, toolbar=no, location=no, directories=no, status=yes, menubar=no, scrollbars=no, resizable=no";
				var _vWin     = window.open("", "payWindow", _vWinopts);
				_vPayFormNm.target = "payWindow";
				_vPayFormNm.submit();
				
			}else{
				
				_vPayFormNm.target = "payFrame";
				_vPayFormNm.submit();
				_vPayFormNm.PayMethod.value = "";
				_vPayFormNm.payType.value   = "";
				
			}
	},

	/*******************************
	 * PG 결제 URL을 반환
	 *******************************/
	getPGUrl: function(formId, mode) {

		var _vResult, _vPcUrl, _vMobileUrl;
		if(mode == "ssb"){
			_vPcUrl     = "https://tpay.smilepay.co.kr/ssb/interfaceURL.jsp";
			_vMobileUrl = "https://tspay.smilepay.co.kr/ssb/interfaceURL";
		}else{
			_vPcUrl     = "https://tpay.smilepay.co.kr/interfaceURL.jsp";
			_vMobileUrl = "https://tspay.smilepay.co.kr/pay/interfaceURL";
		}
		
		var _vFormId = formId ? formId : "paymentForm";
		var _vFilter = "win16|win32|win64|mac|macintel";
		var _vDevice = "";
		
		if(navigator.platform){
			if(_vFilter.indexOf(navigator.platform.toLowerCase()) < 0){
				_vDevice = "mobile";
			}else{
				_vDevice = "pc";
			}
		}else{
			_vDevice = "mobile";
		}
		
		if(_vDevice == "pc"){
			_vResult = _vPcUrl;
			$("#" + _vFormId).attr("accept-charset", "EUC-KR");
		}else{
			_vResult = _vMobileUrl;
			$("#" + _vFormId).attr("accept-charset", "UTF-8");
		}
		
		return _vResult;
	},

	insertPaymentLog: (paymentForm) => {
		var formData = document.getElementById("paymentForm");

		var params = new FormData(formData);
		params.append("p", formData);

		axios.post('/api/smartro/insertPaymentLog.json', params)
		.then((response => {
			this.msgFrom();
		}))
		.catch(function(){
			alert("에러가 발생하였습니다. 관리자에게 문의 바랍니다.");
		})
	},

	/************************
	 * IE버전 확인
	 ************************/
	getVersionOfIE: function() {

		var _vWord    = "";
		var _vVersion = "N/A";

		var _vAgent = navigator.userAgent.toLowerCase();
		var _vName  = navigator.appName;

		if(_vName == "Microsoft Internet Explorer"){	// IE old version ( IE 10 or Lower )
			
			_vWord = "msie";
			
		}else{
			
			if(_vAgent.search("trident") > -1){		// IE 11
				_vWord = "trident/.*rv:";
			}else if(_vAgent.search("edge/") > -1){	// IE 12 ( Microsoft Edge )
				_vWord = "edge/";
			}
			
		}
		
		var _vReg = new RegExp( _vWord + "([0-9]{1,})(\\.{0,}[0-9]{0,1})" );
		
		if(_vReg.exec(_vAgent) != null){
			_vVersion = RegExp.$1 + RegExp.$2;
		}
		
		return _vVersion;
	}

}

export default {

	install (Vue) {

		Vue.prototype.$goPayPop = methods.goPayPop
		Vue.prototype.$msgFrom = methods.msgFrom
		Vue.prototype.$getPGUrl = methods.getPGUrl
		Vue.prototype.$insertPaymentLog = methods.insertPaymentLog
		Vue.prototype.$getVersionOfIE = methods.getVersionOfIE

	}

}