import '@babel/polyfill';
import Vue from 'vue';
import store from './store/index.js';
import App from './App.vue';
import { router } from './routers/router';
import Vuetify from 'vuetify/lib';
import payment from './assets/js/payment.js';
import VueMobileDetection from 'vue-mobile-detection';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueDaumPostcode from 'vue-daum-postcode';
import VueCookies from 'vue-cookies';
import VueConfirmDialog from 'vue-confirm-dialog';
import dayjs from 'dayjs';

const opts = {
  theme: { disable: true },
};
Vue.use(Vuetify);
Vue.use(payment);
Vue.use(VueMobileDetection);
Vue.use(CKEditor);
Vue.use(VueDaumPostcode);
Vue.use(VueCookies);
Vue.use(VueConfirmDialog);

Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

Vue.config.productionTip = false;

Vue.filter('ptDateFormat', function (x) {
  if (x != null) {
    let js_date = new Date(x);

    let year = js_date.getFullYear();
    let month = js_date.getMonth() + 1;
    let day = js_date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return year + '-' + month + '-' + day;
  } else {
    return '';
  }
});

Vue.filter('dateTimeFormat', function (date, format) {
  if (format === 'YYYY-MM-DD hh:mm:ss') {
    return dayjs(date).format('YYYY-MM-DD hh:mm:ss');
  } else if (format === 'YYYY.MM.DD hh:mm:ss') {
    dayjs(date).format('YYYY.MM.DD hh:mm:ss');
  } else if (format === 'YYYY/MM/DD hh:mm:ss') {
    dayjs(date).format('YYYY/MM/DD hh:mm:ss');
  } else if (format === 'YYYY-MM-DD') {
    dayjs(date).format('YYYY-MM-DD');
  } else if (format === 'YYYY.MM.DD') {
    dayjs(date).format('YYYY.MM.DD');
  } else if (format === 'YYYY/MM/DD') {
    dayjs(date).format('YYYY/MM/DD');
  } else if (format === 'year') {
    dayjs(date).year();
  } else if (format === 'month') {
    dayjs(date).month();
  } else if (format === 'day') {
    dayjs(date).day();
  } else if (format === 'hour') {
    dayjs(date).hour();
  } else if (format === 'minute') {
    dayjs(date).minute();
  } else {
    return dayjs(date);
  }
});

Vue.filter('bytes', function (data, to) {
  const const_term = 1024;

  if (to === 'KB') {
    return (data / const_term).toFixed(3) + 'KB';
  } else if (to === 'MB') {
    return (data / const_term ** 2).toFixed(3) + 'MB';
  } else if (to === 'GB') {
    return (data / const_term ** 3).toFixed(3) + 'GB';
  } else if (to === 'TB') {
    return (data / const_term ** 4).toFixed(3) + 'TB';
  } else {
    return 'Please pass valid option';
  }
});

Vue.filter('comma', function (x) {
  if (x != null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
});

Vue.filter('licenseNumber', function (x) {
  if (x != null) {
    return x.toString().replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
  }
});

Vue.filter('phoneNumber', function (phoneNumber) {
  if (!phoneNumber) return phoneNumber;

  phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

  let tmp = '';
  if (phoneNumber.length < 4) {
    return phoneNumber;
  } else if (phoneNumber.length < 7) {
    tmp += phoneNumber.substr(0, 3);
    tmp += '-';
    tmp += phoneNumber.substr(3);
    return tmp;
  } else if (phoneNumber.length == 8) {
    tmp += phoneNumber.substr(0, 4);
    tmp += '-';
    tmp += phoneNumber.substr(4);
    return tmp;
  } else if (phoneNumber.length < 10) {
    if (phoneNumber.substr(0, 2) == '02') {
      //02-123-5678
      tmp += phoneNumber.substr(0, 2);
      tmp += '-';
      tmp += phoneNumber.substr(2, 3);
      tmp += '-';
      tmp += phoneNumber.substr(5);
      return tmp;
    }
  } else if (phoneNumber.length < 11) {
    if (phoneNumber.substr(0, 2) == '02') {
      //02-1234-5678
      tmp += phoneNumber.substr(0, 2);
      tmp += '-';
      tmp += phoneNumber.substr(2, 4);
      tmp += '-';
      tmp += phoneNumber.substr(6);
      return tmp;
    } else {
      //010-123-4567
      tmp += phoneNumber.substr(0, 3);
      tmp += '-';
      tmp += phoneNumber.substr(3, 3);
      tmp += '-';
      tmp += phoneNumber.substr(6);
      return tmp;
    }
  } else {
    //010-1234-5678
    tmp += phoneNumber.substr(0, 3);
    tmp += '-';
    tmp += phoneNumber.substr(3, 4);
    tmp += '-';
    tmp += phoneNumber.substr(7);
    return tmp;
  }
});

// axios.interceptors.response.use(config => {
//   let sessionExpired = config.data.sessionExpired;

//   if (sessionExpired) {
//     axios
//       .post('/api/actionLogout.json')
//       .then(response => {
//         alert('세션이 만료되었습니다.');
//         store.dispatch('logout', null);
//         store.dispatch('companyName', '');
//         router.push('/loginmain');
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   }

//   return config;
// });

// window.EventBus = new Vue();

window.onlyNum = function onlyNum(v) {
  v.value = v.value.replace(/[^\d.]/g, '').replace(/(\.*)\./g, '$1');
  /**
   * 소숫점 가능 정규식
   */
  // v.value = v.value.replace(/[^0-9.]/g, '').replace(/(\.*)\../g, '$1');
};

new Vue({
  vuetify: new Vuetify(opts),
  render: h => h(App),
  router,
  store,
  ClassicEditor,
  CKEditor,
}).$mount('#app');
