function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/unpaid/${view}.vue`);
}

const unpaidRoutes = [
  /* 미납주차요금 */

  // 1. 미납요금 안내
  {
    path: '/unpaidinfo',
    name: 'unpaidinfo',
    component: loadView('UnpaidInfo'),
    meta: {
      category: ['미납주차요금', '미납요금 안내'],
    },
  },
  // 2. 미납요금 조회
  {
    path: '/lookupunpaid',
    name: 'lookupunpaid',
    component: loadView('LookupUnpaid'),
    props: true,
    meta: {
      category: ['미납주차요금', '미납요금 조회/결제'],
    },
  },
  // 3. 미납요금 상세 및 결제
  {
    path: '/unpaidview',
    name: 'unpaidview',
    component: loadView('UnpaidView'),
    props: true,
    meta: {
      category: ['미납주차요금', '미납요금 조회/결제'],
    },
  },
];

export default unpaidRoutes;
