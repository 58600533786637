<template>
  <div class="select" :tabindex="tabindex" @blur="open = false">
    <div class="selTit" :class="{ open: open }" @click="open = !open">
      {{ selected }}
    </div>
    <ul class="selBox" :class="{ selectHide: !open }">
      <li
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default ? this.default : this.options.length > 0 ? this.options[0] : null,
      open: false,
    };
  },
  mounted() {
    this.$emit('input', this.selected);
  },
  watch: {
    $attrs() {
      this.selected = this.$attrs.value;
    },
  },
};
</script>
