function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/prepayment/${view}.vue`);
}

const prepaymentRoutes = [
  /* 주차요금 사전결제 */

  // 1. 사전정산 안내
  {
    path: '/prepaymentinfo',
    name: 'prepaymentinfo',
    component: loadView('PrepaymentInfo'),
    meta: {
      category: ['주차요금 사전결제', '사전결제 안내'],
    },
  },
  // 2. 사전정산 조회
  {
    path: '/lookuppayment',
    name: 'lookuppayment',
    component: loadView('LookupPayment'),
    props: true,
    meta: {
      category: ['주차요금 사전결제', '사전결제 조회/결제'],
    },
  },
  // 3. 사전정산 상세 및 결제
  {
    path: '/paymentview',
    name: 'paymentview',
    component: loadView('PaymentView'),
    props: true,
    meta: {
      category: ['주차요금 사전결제', '사전결제 조회/결제'],
    },
  },
];

export default prepaymentRoutes;
