<template>
  <div id="mapArea" :class="{ small: !active }">
    <div id="map">
      <KakaoMap
        :markerPositions="parkingLocationValue"
        :centerPositions="mapCenterPosition"
        :parkingList="selectParkingList"
        :park="indexMap"
        @kakaoMapMarker="kakaoMapMarker"
      />
    </div>

    <button type="button" class="mapBtn" :class="{ active }" @click="openMap()" />
    <!-- <div class="mapBox">주차장명 : 5 대</div> -->

    <button type="button" class="currentBtn" @click="myPlace"></button>

    <!--localInfo-->
    <div id="localInfo">
      <div class="province">
        <p>파주시</p>
      </div>
      <div class="county">
        <CustomSelect
          :options="['전체', '광탄면', '금촌동', '목동동', '문산읍', '야당동', '와동동', '운정동', '적성면', '조리읍','월롱면']"
          :default="'읍/면/동'"
          v-model="selectRegion"
          @input="changeRegion"
        />
      </div>
    </div>
    <!--//localInfo-->

    <!--fOption-->
    <div class="fOption">
      <ul>
        <li><span class="blue"></span>여유</li>
        <li><span class="org"></span>혼잡</li>
        <li><span class="red"></span>매우혼잡</li>
      </ul>
    </div>
    <!--//fOption-->

    <!--모바일 지도 토글 버튼-->
    <Transition name="fade">
      <button v-if="isScrollTop" type="button" class="moMapBtn" :class="{ active }" @click="openMap()"></button>
    </Transition>
  </div>
</template>

<script>
import axios from '@/util/axios';
import CustomSelect from '../components/CustomSelect.vue';
import KakaoMap from '../components/KakaoMap.vue';

export default {
  components: {
    CustomSelect,
    KakaoMap,
  },

  data() {
    return {
      active: false,
      activeMobile: false,

      mapCenterPosition: [37.75899, 126.78038],
      parkingLocationValue: [],
      indexMap: '-1',
      parkingList: [],
      selectParkingList: undefined,

      markers: [],
      customOverlay: [],
      positions: [],
      map: null,
      originParkings: [],
      clickedMarkerIdx: -1,

      selectRegion: '읍/면/동',

      toggle: false,
      clickChk: false,

      isScrollTop: false,

      userLat: 37.7598688,
      userLng: 126.7801781,
    };
  },

  created() {
    this.getParkingList();
  },

  mounted() {
    document.addEventListener('scroll', this.scroll);
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.scroll);
  },

  methods: {
    myPlace() {
      let locPosition = null;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.userLat = position.coords.latitude;
            this.userLng = position.coords.longitude;
            locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);
            this.displayMarker(locPosition);
          },
          err => {
            locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);
            this.displayMarker(locPosition);
          },
        );
      } else {
        locPosition = new kakao.maps.LatLng(this.userLat, this.userLng);
        this.displayMarker(locPosition);
      }
    },

    displayMarker(locPosition) {
      new kakao.maps.Marker({
        map: this.map,
        position: locPosition,
      });
      this.map.setCenter(locPosition);
    },

    async getParkingList() {
      const res = await axios('get', '/api/parking/searchParkingList');
      this.parkingList = res.data.parkingList;
      this.selectParkingList = res.data.parkingList;
    },

    changeRegion() {
      for (const i of this.customOverlay) {
        i.setMap(null);
      }
      this.clickedMarkerIdx = -1;
      if (this.selectRegion != '전체') {
        this.selectParkingList = this.parkingList.filter(v => v.ADDR == this.selectRegion);
      } else {
        this.selectParkingList = this.parkingList;
      }
    },

    kakaoMapMarker(marker, map, positions, customOverlay) {
      this.markers = [];
      this.positions = [];
      this.markers = marker;
      this.map = map;
      this.positions = positions;
      this.customOverlay = customOverlay;

      for (let i = 0; i < this.markers.length; i++) {
        kakao.maps.event.addListener(this.markers[i], 'mouseover', () => {
          // 마커 호버 이벤트
          this.customOverlay[i].setMap(this.map);
        });
        kakao.maps.event.addListener(this.markers[i], 'mouseout', () => {
          // 마커 호버아웃 이벤트
          this.customOverlay[i].setMap(null);
        });
        kakao.maps.event.addListener(this.markers[i], 'click', () => {
          // 마커 클릭 이벤트
          this.makeClickListener(i);
        });
      }
    },

    customOverlayPinned(marker, customOverlay) {
      kakao.maps.event.addListener(marker, 'mouseout', () => {
        customOverlay.setMap(this.map);
      });
      customOverlay.setMap(this.map);
    },

    customOverlayRemoved(marker, customOverlay) {
      kakao.maps.event.addListener(marker, 'mouseout', () => {
        customOverlay.setMap(null);
      });
      customOverlay.setMap(null);
    },

    makeClickListener(idx) {
      if (this.clickedMarkerIdx == -1) {
        this.customOverlayPinned(this.markers[idx], this.customOverlay[idx]);
        this.clickedMarkerIdx = idx;
      } else if (this.clickedMarkerIdx != idx) {
        this.customOverlayRemoved(this.markers[this.clickedMarkerIdx], this.customOverlay[this.clickedMarkerIdx]);
        this.customOverlayPinned(this.markers[idx], this.customOverlay[idx]);
        this.clickedMarkerIdx = idx;
      } else {
        this.customOverlayRemoved(this.markers[this.clickedMarkerIdx], this.customOverlay[this.clickedMarkerIdx]);
        this.clickedMarkerIdx = -1;
      }
    },

    openMap() {
      this.active = !this.active;

      let mapContainer = document.getElementById('map');
      if (this.active) {
        mapContainer.style.width = window.innerWidth + 'px';
      } else {
        mapContainer.style.width = '530px';
      }
      this.map.relayout();
      const bounds = new kakao.maps.LatLngBounds();
      for (const i of this.positions) {
        bounds.extend(i);
      }
      if (bounds.ha != Infinity) {
        this.map.setBounds(bounds);
        //파주시 요청사항으로 zoom in
        if (navigator.userAgent.includes('Mobile') && this.selectParkingList.length == this.parkingList.length) {
          this.map.setLevel(this.map.getLevel() - 2);
          this.map.setCenter(new kakao.maps.LatLng(this.mapCenterPosition[0], this.mapCenterPosition[1]));
        }
      } else {
        this.map.setLevel(6);
        this.map.setCenter(new kakao.maps.LatLng(this.mapCenterPosition[0], this.mapCenterPosition[1]));
      }
    },

    scroll() {
      const scollPosition = window.scrollY;
      if (scollPosition === 0) {
        this.isScrollTop = false;
      } else {
        this.isScrollTop = true;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
