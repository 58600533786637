<template>
  <div>
    <div id="map"></div>
    <div id="map2"></div>
    <div id="map3"></div>
  </div>
</template>

<script>
//TODO: 필수 교체
const KAKAO_MAP_API_KEY = `${process.env.VUE_APP_KAKAO_MAP_API_KEY}`;

export default {
  name: 'KakaoMap',
  props: {
    centerPositions: [],
    parkingList: [],
    park: String,
    anotherMap: Number,
    isSingleElement: Boolean,
  },

  data() {
    return {
      markers: [],
      positions: [],
      customOverlay: [],
      ckImg: null,
      imageSize: null,
      imageOption: null,
      map: null,
    };
  },

  watch: {
    parkingList() {
      if (this.parkingList?.length >= 0) {
        this.initMap();
      }
    },
  },

  mounted() {
    if (window.kakao && window.kakao.maps) {
      if (this.isSingleElement) {
        this.initMap();
      } else {
        kakao.maps.load(() => this.initMap);
      }
      return;
    }
    const script = document.createElement('script');
    script.onload = () => kakao.maps.load(this.initMap);
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${KAKAO_MAP_API_KEY}`;
    document.head.appendChild(script);
  },

  methods: {
    // 공영 파랑 마커
    publicMarkerBlue() {
      return require('../assets/images/map/public_marker_blue.png');
    },

    // 공영 빨강 마커
    publicMarkerRed() {
      return require('../assets/images/map/public_marker_red.png');
    },

    // 공영 주황 마커
    publicMarkerOrg() {
      return require('../assets/images/map/public_marker_org.png');
    },

    // 민영 파랑 마커
    privateMarkerBlue() {
      return require('../assets/images/map/private_marker_blue.png');
    },

    // 민영 빨강 마커
    privateMarkerRed() {
      return require('../assets/images/map/private_marker_red.png');
    },

    // 민영 주황 마커
    privateMarkerOrg() {
      return require('../assets/images/map/private_marker_org.png');
    },

    initMap() {
      let container = null;
      if (this.anotherMap == 2) {
        container = document.getElementById('map2');
      } else if (this.anotherMap == 3) {
        container = document.getElementById('map3');
      } else {
        container = document.getElementById('map');
      }

      try {
        const options = {
          center: new kakao.maps.LatLng(this.centerPositions[0], this.centerPositions[1]),
          level: 5,
        };
        if (this.map == null) {
          this.map = new kakao.maps.Map(container, options);
        }
        if (this.park) {
          container.style.height = window.innerHeight + 'px';
        }
        this.map.relayout();
        this.map.setLevel(6);
        this.map.setCenter(new kakao.maps.LatLng(this.centerPositions[0], this.centerPositions[1]));
        this.displayMarker();
      } catch (ReferenceError) {
        return;
      }
    },

    displayMarker() {
      if (this.markers != []) {
        for (const i of this.markers) {
          i.setMap(null);
        }
        this.markers = [];
      }
      this.positions = [];

      const bounds = new kakao.maps.LatLngBounds();

      // 마커이미지의 크기입니다
      this.imageSize = new kakao.maps.Size(29, 46);
      this.imageOption = { offset: new kakao.maps.Point(12, 34) };

      for (let i = 0; i < this.parkingList.length; i++) {
        if (this.parkingList[i].LAT % 1 != 0 && !isNaN(this.parkingList[i].LAT) && this.parkingList[i].LAT != null) {
          let content = '';
          const position = new kakao.maps.LatLng(this.parkingList[i].LAT, this.parkingList[i].LNG);
          this.positions.push(position);

          const parkingCnt =
            ((this.parkingList[i].CELL_CNT - this.parkingList[i].PARK_COUNT) / this.parkingList[i].CELL_CNT) * 100;

          this.markers.push(
            new kakao.maps.Marker({
              map: this.map,
              position: position,
              clickable: true,
              image: new kakao.maps.MarkerImage(
                this.setMarkerImages(parkingCnt, this.parkingList[i].PARKING_GUBUN),
                this.imageSize,
                this.imageOption,
              ),
            }),
          );

          if (this.parkingList[i].PARK_COUNT == null || this.parkingList[i].PARK_COUNT < 0) {
            this.parkingList[i].PARK_COUNT = 0;
          }

          if (this.parkingList[i].PARKING_NM != '시청주차장_' && !this.parkingList[i].isTouristDestin) {
            content =
              '<div class="mapBox">' +
              '<div>' +
              '<strong>' +
              this.parkingList[i].PARKING_NM +
              '</strong>' +
              '</div>' +
              '</div>';
          } else if (this.parkingList[i].isTouristDestin) {
            content = '<div class="mapBox">' + '<strong>' + this.parkingList[i].PARKING_NM + '</strong>' + '</div>';
          } else {
            content =
              '<div class="mapBox" style="top: -148px;">' +
              '<div>' +
              '<strong>' +
              this.parkingList[i].PARKING_NM +
              '</strong>' +
              '<br>' +
              '<span>광장 주차장</span>' +
              ' ' +
              this.parkingList[i].시청광장주차장[0] +
              '면' +
              '<br>' +
              '<span>장애인 주차장</span>' +
              ' ' +
              this.parkingList[i].시청장애인주차장[0] +
              '면' +
              '<br>' +
              '<span>철골 주차장</span>' +
              ' ' +
              this.parkingList[i].시청철골주차장[0] +
              '면' +
              // '<br>' +
              // '<span>(신)철골 주차장</span>' +
              // ' ' +
              // this.parkingList[i].시청신철골주차장[0] +
              // '면' +
              '</div>' +
              '</div>';
          }

          this.customOverlay[i] = new kakao.maps.CustomOverlay({
            position: this.positions[i],
            content: content,
            yAnchor: 0.1,
          });

          bounds.extend(position);
        }
      }
      if (bounds.ha != Infinity) {
        this.map.setBounds(bounds);
      }
      this.$emit('kakaoMapMarker', this.markers, this.map, this.positions, this.customOverlay, this.parkingList);
    },

    setMarkerImages(parkingCnt, parkingGubun) {
      if (parkingCnt >= 0 && parkingCnt < 60) {
        return parkingGubun == '민영' ? this.privateMarkerBlue() : this.publicMarkerBlue();
      } else if (parkingCnt >= 60 && parkingCnt < 80) {
        return parkingGubun == '민영' ? this.privateMarkerOrg() : this.publicMarkerOrg();
      } else if (parkingCnt >= 80) {
        return parkingGubun == '민영' ? this.privateMarkerRed() : this.publicMarkerRed();
      } else {
        return parkingGubun == '민영' ? this.privateMarkerBlue() : this.publicMarkerBlue();
      }
    },
  },
};
</script>
