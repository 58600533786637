function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/period/${view}.vue`);
}

const periodRoutes = [
  /* 정기권서비스 */

  // 1. 정기권 안내
  {
    path: '/periodinfo',
    name: 'periodinfo',
    component: loadView('PeriodInfo'),
    meta: {
      category: ['공영주차 정기권', '공영주차 정기권'],
    },
  },
  // 2. 정기권 개인정보 동의
  {
    path: '/periodagreement',
    name: 'periodagreement',
    component: loadView('PeriodAgreement'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2. 정기권 신청 기본 정보입력
  {
    path: '/periodapplication',
    name: '/periodapplication',
    component: loadView('PeriodApplication'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2-1. 정기권 신청 정보 입력
  {
    path: '/periodinputinfo',
    name: '/periodinputinfo',
    component: loadView('PeriodInputInfo'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2-2. 정기권 신청
  {
    path: '/periodapply',
    name: '/periodapply',
    component: loadView('PeriodApply'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 3. 정기권 조회
  {
    path: '/periodlookup',
    name: 'periodlookup',
    component: loadView('PeriodLookup'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 조회/결제'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 4. 졍기권 상세 및 결제
  {
    path: '/periodview',
    name: 'periodview',
    component: loadView('PeriodView'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 조회/결제'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 5. 정기권 해지
  {
    path: '/periodcancel',
    name: 'periodcancel',
    component: loadView('PeriodCancel'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 해지'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 6. 정기권 해지 상세
  {
    path: '/periodcancelview',
    name: 'periodcancelview',
    component: loadView('PeriodCancelView'),
    props: true,
    meta: {
      category: ['공영주차 정기권', '정기권 해지'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
];

export default periodRoutes;
