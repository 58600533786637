<template>
  <div v-show="showModal">
    <div class="popup">
      <div class="popHead">
        <p class="title"><slot name="info"></slot></p>
      </div>
      <div class="popCont">
        <div class="inner">
          <slot name="content"></slot>
        </div>
      </div>
      <div>
        <slot name="button"></slot>
      </div>
    </div>
    <div class="dim">
      <slot name="dimarea"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: true,
    };
  },
};
</script>
