<template>
  <ul class="breadCrumb">
    <li class="home" @click="$router.push('/main')">Home</li>
    <li>
      <p class="pageTxt">{{ firTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in firMenuList" :key="index">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
    <li>
      <p class="pageTxt">{{ secTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in secMenuList" :key="index">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
    <li v-if="trdTitle !== ''">
      <p class="pageTxt">{{ trdTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in trdMenuList" :key="index" class="blod">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    isBusiness() {
      return this.$store.state.empDiv === 'BSN';
    },
    idAdmin() {
      return this.$store.state.authorCode === 'ROLE_ADMIN';
    },
    loginType() {
      return this.$store.state.authorCode ? this.$store.state.authorCode : '';
    },
  },

  created() {
    this.getSnbMenuList();
    this.getTitle();
  },

  data() {
    return {
      firMenuList: [],
      secMenuList: [],
      trdMenuList: [],
      firTitle: '',
      secTitle: '',
      trdTitle: '',
    };
  },

  watch: {
    $route() {
      this.getTitle();
    },
  },

  methods: {
    getSnbMenuList() {
      const copy = cloneDeep(this.menuList);

      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (!this.isLogin) {
            if (menu[i].auth === 'ROLE_ADMIN' || menu[i].link.includes('mypage')) {
              menu[i] = '';
            }
          } else {
            if (!this.idAdmin && menu[i].auth !== this.loginType && menu[i].auth !== undefined) {
              menu[i] = '';
            }
          }
          if (menu[i].title === this.firTitle) {
                      this.secMenuList = menu[i].menuList;
                      menu[i].menuList.map(item => {
                        if (item.title === this.secTitle) {
                          this.trdMenuList = item.menuList;
                        }
                      });
                    }

          recursion(menu[i].menuList);
        }
      };

      recursion(copy);

      this.firMenuList = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i != '');
        }
        return v;
      });
    },

    getTitle() {
          this.firTitle = '';
                this.secTitle = '';
                this.firTitle = this.$route.meta.category[0];
                if (this.$route.meta.category[1]) {
                  this.secTitle = this.$route.meta.category[1];
                }
                this.getSnbMenuList();
        },
  },
};
</script>
