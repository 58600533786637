function loadViewPopup(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/popup/${view}.vue`);
}
function loadViewBanner(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/banner/${view}.vue`);
}
function loadViewMember(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/member/${view}.vue`);
}
function loadViewUser(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/user/${view}.vue`);
}
function loadViewDept(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/department/${view}.vue`);
}
function loadViewTeam(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/team/${view}.vue`);
}
function loadViewMenu(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/system/menu/${view}.vue`);
}

const systemRoutes = [
  /* 시스템 관리 */

  // 팝업 관리
  // 1. 조회
  {
    path: '/popuplist',
    name: 'popuplist',
    component: loadViewPopup('PopupList'),
    meta: {
      category: ['시스템관리', '팝업 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/popupview',
    name: 'popupview',
    component: loadViewPopup('PopupView'),
    meta: {
      category: ['시스템관리', '팝업 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/popupenrollment',
    name: 'popupenrollment',
    component: loadViewPopup('PopupEnrollment'),
    meta: {
      category: ['시스템관리', '팝업 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/popupupdate',
    name: 'popupupdate',
    component: loadViewPopup('PopupUpdate'),
    meta: {
      category: ['시스템관리', '팝업 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 배너 관리
  // 1. 조회
  {
    path: '/bannerlist',
    name: 'bannerlist',
    component: loadViewBanner('BannerList'),
    meta: {
      category: ['시스템관리', '배너 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/bannerview',
    name: 'bannerview',
    component: loadViewBanner('BannerView'),
    meta: {
      category: ['시스템관리', '배너 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/bannerenrollment',
    name: 'bannerenrollment',
    component: loadViewBanner('BannerEnrollment'),
    meta: {
      category: ['시스템관리', '배너 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/bannerupdate',
    name: 'bannerupdate',
    component: loadViewBanner('BannerUpdate'),
    meta: {
      category: ['시스템관리', '배너 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 회원정보 관리
  // 1. 조회
  {
    path: '/generalmembership',
    name: 'generalmembership',
    component: loadViewMember('GeneralMembership'),
    meta: {
      category: ['시스템관리', '회원정보 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/generalmembershipview',
    name: 'generalmembershipview',
    component: loadViewMember('GeneralMembershipView'),
    meta: {
      category: ['시스템관리', '회원정보 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 관리자계정 관리
  // 1. 조회
  {
    path: '/usermanagement',
    name: 'usermanagement',
    component: loadViewUser('UserManagement'),
    meta: {
      category: ['시스템관리', '관리자계정 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/userview',
    name: 'userview',
    component: loadViewUser('UserView'),
    meta: {
      category: ['시스템관리', '관리자계정 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/userenrollment',
    name: 'userenrollment',
    component: loadViewUser('UserEnrollment'),
    meta: {
      category: ['시스템관리', '관리자계정 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/userupdate',
    name: 'userupdate',
    component: loadViewUser('UserUpdate'),
    meta: {
      category: ['시스템관리', '관리자계정 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 권한그룹 관리
  {
    path: '/userauthoritylist',
    name: 'userauthoritylist',
    component: () =>
      import(/* webpackChunkName: "view-UserAuthorityList-vue" */ '@/html/system/authority/UserAuthorityList.vue'),
    meta: {
      category: ['시스템관리', '권한그룹 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 부서 관리
  // 1. 조회
  {
    path: '/departmentlist',
    name: 'departmentlist',
    component: loadViewDept('DepartmentList'),
    meta: {
      category: ['시스템관리', '부서 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/departmentview',
    name: 'departmentview',
    component: loadViewDept('DepartmentView'),
    meta: {
      category: ['시스템관리', '부서 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/departmentenrollment',
    name: 'departmentenrollment',
    component: loadViewDept('DepartmentEnrollment'),
    meta: {
      category: ['시스템관리', '부서 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/departmentupdate',
    name: 'departmentupdate',
    component: loadViewDept('DepartmentUpdate'),
    meta: {
      category: ['시스템관리', '부서 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 팀 관리
  // 1. 조회
  {
    path: '/teamlist',
    name: 'teamlist',
    component: loadViewTeam('TeamList'),
    meta: {
      category: ['시스템관리', '팀 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/teamview',
    name: 'teamview',
    component: loadViewTeam('TeamView'),
    meta: {
      category: ['시스템관리', '팀 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/teamenrollment',
    name: 'teamenrollment',
    component: loadViewTeam('TeamEnrollment'),
    meta: {
      category: ['시스템관리', '팀 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/teamupdate',
    name: 'teamupdate',
    component: loadViewTeam('TeamUpdate'),
    meta: {
      category: ['시스템관리', '팀 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 메뉴 관리
  // 1. 조회
  {
    path: '/menulist',
    name: 'menulist',
    component: loadViewMenu('MenuList'),
    meta: {
      category: ['시스템관리', '메뉴 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/menuview',
    name: 'menuview',
    component: loadViewMenu('MenuView'),
    meta: {
      category: ['시스템관리', '메뉴 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/menuenrollment',
    name: 'menuenrollment',
    component: loadViewMenu('MenuEnrollment'),
    meta: {
      category: ['시스템관리', '메뉴 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/menuupdate',
    name: 'menuupdate',
    component: loadViewMenu('MenuUpdate'),
    meta: {
      category: ['시스템관리', '메뉴 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 접속 로그 정보
  {
    path: '/accessinfo',
    name: 'accessinfo',
    component: () => import(/* webpackChunkName: "view-AccessInfo-vue" */ '@/html/system/access/AccessInfo.vue'),
    meta: {
      category: ['시스템관리', '접속 로그 정보'],
      authorization: ['ROLE_ADMIN'],
    },
  },
];

export default systemRoutes;
