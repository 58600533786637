function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/parking/${view}.vue`);
}

const parkingRoutes = [
  /* 주차장안내 */

  // 주차장 소개
  {
    path: '/parkinfo',
    name: 'parkinfo',
    component: loadView('ParkingInfo'),
    meta: {
      category: ['주차장안내', '주차장 소개'],
    },
  },

  // 주차장 검색
  // 1. 지도검색
  {
    path: '/parkingmap',
    name: 'parkingmap',
    component: loadView('ParkingMap'),
    meta: {
      category: ['주차장안내', '주차장 검색'],
    },
  },
  // 2. 주소검색
  {
    path: '/parkingaddress',
    name: 'parkingaddress',
    component: loadView('ParkingAddress'),
    props: true,
    meta: {
      category: ['주차장안내', '주차장 검색'],
    },
  },

  // 주차장 요금안내
  {
    path: '/parkfeeinformation',
    name: 'parkfeeinformation',
    component: loadView('ParkFeeInformation'),
    meta: {
      category: ['주차장안내', '주차장 요금안내'],
    },
  },
];

export default parkingRoutes;
