<template>
  <footer id="footer">
    <div class="inner">
      <div class="flex">
        <h1 class="logo">
          <div class="flogoImg"><span class="hidden">로고</span></div>
        </h1>
        <div class="footUtil">
          <ul class="footLink">
            <li>
              <button title="개인정보처리방침" @click="openLink"><b>개인정보처리방침</b></button>
            </li>
          </ul>
          <address>
            <ul>
                <li>대표번호 : 031-940-4114</li>
            </ul>
            <ul>
                <li>주차장 이용문의</li>
                <li>공영주차장 : 031-909-6305(6)</li>
                <li>놀이구름 : 031-909-6270</li>
                <li>마장호수 : 031-950-1948</li>
                <li>감악산 : 031-950-1951</li>
            </ul>
          </address>
        </div>
      </div>
      <article>
        <div class="famSelect">
          <label for="familySite" class="hidden">파주시 패밀리 사이트</label>
          <select
            title="패밀리사이트 선택"
            @change="goExternalSite"
            @keydown.up="eventStem($event)"
            @keydown.right="eventStem($event)"
            @keydown.down="eventStem($event)"
            @keydown.left="eventStem($event)"
            ref="moveSite"
          >
            <option value="/main" selected>Family Site</option>
            <option value="https://www.paju.go.kr/index.do">파주시청</option>
            <option value="https://pajuutc.or.kr/">파주도시관광공사</option>
            <option value="https://www.elis.go.kr/locgovalr/locgovSeAlrList?ctpvCd=41&amp;sggCd=510">자치법규</option>
            <option value="https://traffic.paju.go.kr/main.view">파주시 교통정보센터</option>
            <option value="http://car.paju.go.kr">주정차 단속조회</option>
            <option value="http://parkingsms.paju.go.kr/">주정차단속 문자알림</option>
          </select>
        </div>
        <p class="copyright">COPYRIGHT(C) 2022, PAJU CITY ALL RIGHTS RESERVED</p>
      </article>
    </div>

    <email-modal v-if="emailModalShow">
      <template #info> 이메일무단수집거부 </template>
      <template #content>
        <article>
          <div class="terms">
            <strong>정보통신망법 제 50조의 2 (전자우편주소의 무단 수집행위 등 금지)</strong>
            <p>
              <em>①</em> 누구든지 전자우편 주소의 수집을 거부하는 의사가 명시된 인터넷 홈페이지에서 자동으 로 전자우편
              주소를 수집하는 프로그램 그 밖의 기술적 장치를 이용하여 전자우편 주소를 수 집하여서는 아니된다.
            </p>
            <p><em>②</em> 누구든지 제1항의 규정을 위반하여 수집된 전자우편주소를 판매 유통하여서는 아니된다.</p>
            <p>
              <em>③</em> 누구든지 제1항 및 제2항의 규정에 의하여 수집, 판매 및 유통이 금지된 전장편주소임을 알고 이를
              정보전송에 이용하여서는 아니된다.
            </p>
          </div>
        </article>
      </template>
      <template #button>
        <button class="btn closeBtn" @click="CloseBtn"><span class="hidden">닫기</span></button>
      </template>
    </email-modal>
  </footer>
</template>

<script>
import EmailModal from '../../components/PopupType1.vue';

export default {
  data() {
    return {
      modalShow: false,
      isActive: false,
      emailModalShow: false,
    };
  },

  components: {
    EmailModal,
  },

  methods: {
    // 외부사이트 오픈
    // goExternalSite(url) {
    //     window.open(url, "_blank");
    // },

    goExternalSite() {
      if (/Mobi/i.test(window.navigator.userAgent) || /Safari/i.test(window.navigator.userAgent)) {
        location.href = this.$refs.moveSite.value;
      } else {
        window.open(this.$refs.moveSite.value, '_blank');
      }
    },

    goFamilySite(e) {
      if (e.target.value != '' && e.target.value != null) {
        this.goExternalSite(e.target.value);
      }
    },

    eventStem(e) {
      switch (e.keyCode) {
        case 37:
          e.preventDefault();
          break;
        case 38:
          e.preventDefault();
          break;
        case 39:
          e.preventDefault();
          break;
        case 40:
          e.preventDefault();
          break;
      }
    },

    CloseBtn() {
      this.emailModalShow = false;
    },

    EmailModal() {
      this.emailModalShow = true;
    },

    openLink() {
      window.open('https://www.paju.go.kr/www/page_guide/page_guide_01/page_guide_01_info.jsp', '_blank');
    },
  },
};
</script>
