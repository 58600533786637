function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/nonstop/${view}.vue`);
}

const nonstopRoutes = [
  /* 무정차 정산 */

  // 1. 안내
  {
    path: '/nonstopinfo',
    name: 'nonstopinfo',
    component: loadView('NonstopInfo'),
    meta: {
      category: ['무정차 안내', '무정차 시스템 안내'],
    },
  },
  // 2. 신청
  {
    path: '/nonstopapplication',
    name: 'nonstopapplication',
    component: loadView('NonstopApplication'),
    meta: {
      category: ['무정차 안내', '무정차 정산 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 3. 결제카드 등록
  {
    path: '/nonstopcardregist',
    name: 'nonstopcardregist',
    component: loadView('NonstopCardRegist'),
    props: true,
    meta: {
      category: ['무정차 안내', '무정차 정산 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 4. 무정차 차량 등록
  {
    path: '/nonstopcarregist',
    name: 'nonstopcarregist',
    component: loadView('NonstopCarRegist'),
    props: true,
    meta: {
      category: ['무정차 안내', '무정차 정산 신청'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
];

export default nonstopRoutes;
