function loadViewNotice(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/manager/notice/${view}.vue`);
}
function loadViewFaq(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/manager/faq/${view}.vue`);
}
function loadViewPeriod(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/manager/period/${view}.vue`);
}
function loadViewReduction(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/manager/reduction/${view}.vue`);
}

function loadViewDiscount(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/manager/discount/${view}.vue`);
}

const managerRoutes = [
  /* 관리자 메뉴 */

  // 공지사항 관리
  // 1. 조회
  {
    path: '/noticemanagement',
    name: 'noticemanagement',
    component: loadViewNotice('NoticeManagement'),
    meta: {
      category: ['관리자메뉴', '공지사항 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/noticemanagementview',
    name: 'noticemanagementview',
    component: loadViewNotice('NoticeManagementView'),
    meta: {
      category: ['관리자메뉴', '공지사항 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/noticemanagementenrollment',
    name: 'noticemanagementenrollment',
    component: loadViewNotice('NoticeManagementEnrollment'),
    meta: {
      category: ['관리자메뉴', '공지사항 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/noticemanagementupdate',
    name: 'noticemanagementupdate',
    component: loadViewNotice('NoticeManagementUpdate'),
    meta: {
      category: ['관리자메뉴', '공지사항 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 자주하는 질문 관리
  // 1. 조회
  {
    path: '/faqmanagement',
    name: 'faqmanagement',
    component: loadViewFaq('FaQManagement'),
    meta: {
      category: ['관리자메뉴', '자주하는 질문 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/faqmanagementview',
    name: 'faqmanagementview',
    component: loadViewFaq('FaQManagementView'),
    meta: {
      category: ['관리자메뉴', '자주하는 질문 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 3. 등록
  {
    path: '/faqmanagementenrollment',
    name: 'faqmanagementenrollment',
    component: loadViewFaq('FaQManagementEnrollment'),
    meta: {
      category: ['관리자메뉴', '자주하는 질문 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 4. 수정
  {
    path: '/faqmanagementupdate',
    name: 'faqmanagementupdate',
    component: loadViewFaq('FaQManagementUpdate'),
    meta: {
      category: ['관리자메뉴', '자주하는 질문 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 정기권주차장 관리
  {
    path: '/periodparking',
    name: 'periodparking',
    component: loadViewPeriod('PeriodParking'),
    meta: {
      category: ['관리자메뉴', '정기권주차장 관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 지자체 감면관리
  // 1. 조회
  {
    path: '/reductionmanagement',
    name: 'reductionmanagement',
    component: loadViewReduction('ReductionManagement'),
    meta: {
      category: ['관리자메뉴', '지자체 감면관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },
  // 2. 상세
  {
    path: '/reductionmanagementview',
    name: 'reductionmanagementview',
    component: loadViewReduction('ReductionManagementView'),
    meta: {
      category: ['관리자메뉴', '지자체 감면관리'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 웹할인 사용내역
  // 1. 조회
  {
    path: '/discount',
    name: 'discount',
    component: loadViewDiscount('Discount'),
    meta: {
      category: ['관리자메뉴', '웹할인 사용내역'],
      authorization: ['ROLE_ADMIN'],
    },
  },

  // 2. 상세
  {
    path: '/discountview',
    name: 'discountview',
    component: loadViewDiscount('DiscountView'),
    meta: {
      category: ['관리자메뉴', '웹할인 사용내역'],
      authorization: ['ROLE_ADMIN'],
    },
  }
];

export default managerRoutes;
