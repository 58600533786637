function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/board/${view}.vue`);
}

const boardRoutes = [
  /* 열린공간 */

  // 공지사항
  {
    path: '/noticelist',
    name: 'noticelist',
    component: loadView('NoticeList'),
    meta: {
      category: ['공지사항', '공지사항'],
    },
  },
  // 공지사항 상세
  {
    path: '/noticeview',
    name: 'noticeview',
    component: loadView('NoticeView'),
    meta: {
      category: ['공지사항', '공지사항'],
    },
  }

];

export default boardRoutes;
