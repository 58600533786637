function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/util/${view}.vue`);
}

function businessloadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/util/business/${view}.vue`);
}

const utilRoutes = [
  /* Util */

  {
    path: '/codinglist',
    name: 'codinglist',
    component: () => import('@/html/guideline/CodingList.vue'),
  },

  // 전체 검색
  {
    path: '/searchlist',
    name: 'searchlist',
    component: loadView('SearchList'),
  },

  // 로그인
  {
    path: '/login',
    name: 'login',
    component: loadView('Login'),
    meta: {
      onlyGuest: true,
    },
  },
  // 사업자 로그인
  {
    path: '/loginbusiness',
    name: 'loginbusiness',
    component: loadView('LoginBusiness'),
    meta: {
      onlyGuest: true,
    },
  },

  // 회원가입
  {
    path: '/join',
    name: 'join',
    component: loadView('Join'),
    meta: {
      onlyGuest: true,
    },
  },

  // 1. 동의
  {
    path: '/joinagreement',
    name: 'joinagreement',
    component: loadView('JoinAgreement'),
    meta: {
      onlyGuest: true,
    },
  },

  // 2. 정보입력
  {
    path: '/joininformation',
    name: 'joininformation',
    component: loadView('JoinInformation'),
    meta: {
      onlyGuest: true,
    },
  },

  // 3. 가입신청
  {
    path: '/joincomplete',
    name: 'joincomplete',
    component: loadView('JoinComplete'),
    meta: {
      onlyGuest: true,
    },
  },

  // 사업자 회원가입
  // 1. 동의
  {
    path: '/business/joinagreement',
    name: 'businessjoinagreement',
    component: businessloadView('businessJoinAgreement'),
    meta: {
      onlyGuest: true,
    },
  },

  // 2. 정보입력
  {
    path: '/business/joininformation',
    name: 'businessjoininformation',
    component: businessloadView('businessJoinInformation'),
    meta: {
      onlyGuest: true,
    },
  },

  // 3. 가입신청
  {
    path: '/business/joincomplete',
    name: 'businessjoincomplete',
    component: businessloadView('businessJoinComplete'),
    meta: {
      onlyGuest: true,
    },
  },
];

export default utilRoutes;
