<template>
  <section id="APP">
    <div v-if="$route.meta.title === '웹 할인'">
      <router-view></router-view>
    </div>
    <div id="wrap" v-else>
      <gnb-area></gnb-area>
      <div id="containter">
        <main-layout></main-layout>
        <footer-area></footer-area>
      </div>
      <main-map v-if="$route.name === 'main'"></main-map>
    </div>
    <vue-confirm-dialog></vue-confirm-dialog>
  </section>
</template>
<script>
import GnbArea from './html/layout/GnbArea.vue';
import MainLayout from './html/layout/MainLayout.vue';
import FooterArea from './html/layout/FooterArea.vue';
import MainMap from './components/MainMap.vue';

export default {
  components: {
    GnbArea,
    MainLayout,
    FooterArea,
    MainMap,
  },
};
</script>
