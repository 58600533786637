function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/mypage/${view}.vue`);
}

const mypageRoutes = [
  /* 마이페이지(일반) */

  // 1. 회원정보
  {
    path: '/mypagegeneral',
    name: 'mypagegeneral',
    component: loadView('MypageGeneral'),
    meta: {
      category: ['마이페이지', '회원정보'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2. 회원정보 - 수정
  {
    path: '/mypagegeneralupdate',
    name: 'mypagegeneralupdate',
    component: loadView('MypageGeneralUpdate'),
    meta: {
      category: ['마이페이지', '회원정보'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },

  // 1. 일반할인
  {
    path: '/mypagegeneraldiscount',
    name: 'mypagegeneraldiscount',
    component: loadView('MypageGeneralDiscount'),
    meta: {
      category: ['마이페이지', '일반할인'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },

  // 1. 서비스이용내역 - 정기권
  {
    path: '/mypagegeneralpayment',
    name: 'mypagegeneralpayment',
    component: loadView('MypageGeneralPayment'),
    meta: {
      category: ['마이페이지', '서비스 이용내역'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 2. 서비스이용내역 - 무정차
  {
    path: '/mypagegeneralnonstop',
    name: 'mypagegeneralnonstop',
    component: loadView('MypageGeneralNonstop'),
    meta: {
      category: ['마이페이지', '서비스 이용내역'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },
  // 3. 서비스이용내역 - 미납요금
  {
    path: '/mypagegeneralunpaid',
    name: 'mypagegeneralunpaid',
    component: loadView('MypageGeneralUnpaid'),
    meta: {
      category: ['마이페이지', '서비스 이용내역'],
      authorization: ['ROLE_USER', 'ROLE_ADMIN'],
      empcategory: ['USR'],
    },
  },

  /* 마이페이지(사업자) */

  // 1. 회원정보
  {
    path: '/mypagebusiness',
    name: 'mypagebusiness',
    component: loadView('MypageBusiness'),
    meta: {
      category: ['마이페이지', '회원정보'],
      // authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
      // empcategory: ['BSN'],
    },
  },
  // 2. 회원정보 - 수정
  {
    path: '/mypagebusinessupdate',
    name: 'mypagebusinessupdate',
    component: loadView('MypageBusinessUpdate'),
    meta: {
      category: ['마이페이지', '회원정보'],
      // authorization: ['ROLE_STORE', 'ROLE_ADMIN'],
      // empcategory: ['BSN'],
    },
  },
];

export default mypageRoutes;
