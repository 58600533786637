function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/html/subpage/board/${view}.vue`);
}

const faqRoutes = [
  // 자주하는 질문
  {
    path: '/faqview',
    name: 'faqview',
    component: loadView('FaqView'),
    meta: {
      category: ['자주하는 질문', '자주하는 질문'],
    },
  },
];

export default faqRoutes;
