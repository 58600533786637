<template>
  <div v-if="$route.name === 'main'" class="main">
    <router-view></router-view>
  </div>
  <div v-else-if="$route.meta.category">
    <section id="category">
      <top-navigation></top-navigation>
    </section>
    <router-view></router-view>
  </div>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue';

export default {
  data() {
    return {};
  },
  components: {
    TopNavigation,
  },
};
</script>
