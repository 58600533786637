import axios from 'axios';
import { router } from '../routers/router';
import store from '../store/index.js';

const instance = axios.create({
  timeout: 30000,
});

const rotuerMove = route => {
  router.replace(route).catch(err => {
    if (
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
    ) {
      console.error(err);
    }
  });
};

const axiosHook = async (method, url, params, config = {}) => {
  let response = null;
  try {
    switch (method) {
      case 'post':
        response = await instance.post(url, params, config);
        break;
      case 'get':
        response = await instance.get(
          url,
          {
            params,
          },
          config,
        );
        break;
      default:
        break;
    }
  } catch (error) {
    if (!response) {
      console.error(error);
      return;
    }
  }
  if (response.data.sessionExpired || response.data.httpStatusCode === 403) {
    if (store.state.isLogin && !store.state.checkSessionExpired) {
      store.dispatch('logout', null);
      store.dispatch('companyName', '');
      store.dispatch('sessionExpired', true);
      store.dispatch('isLogin', false);
      store.dispatch('checkSessionExpired', true);
      alert('다시 로그인 해주세요.');
      rotuerMove('/login');
      return;
    }
  }


  return response;
};

export default axiosHook;
